import type { InputHTMLAttributes } from "react";
import { forwardRef } from "react";
import { cn } from "../../helpers/styles";

export type InputProps = InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
	({ className, ...props }, ref) => {
		return (
			<input
				className={cn(
					"w-full appearance-none rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm shadow-sm file:border-0 file:bg-transparent file:font-medium file:text-sm placeholder:text-gray-400 disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid=true]:border-red-600 aria-[invalid=true]:text-red-600 md:text-base",
					className,
				)}
				ref={ref}
				{...props}
			/>
		);
	},
);

Input.displayName = "Input";
